import { FC, ReactNode, useState, useEffect } from 'react';
import { useAppContext } from 'provider/AppProvider/AppProvider';

import Footer from 'components/modules/Footer';
import Header from 'components/modules/Header/Header';
import Loader from 'components/modules/Loader/Loader';
import {
  useGetUserDataByUsername,
  useGetAppAboutByUUID,
} from 'services/queries/app';
import SEOFavicoComponent from 'components/modules/SEOFavicon';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  useGetAppAboutByUUID();
  const { setup, isLoading } = useAppContext();
  const [isAppCtxLoading, setIsAppCtxLoading] = useState<boolean>(isLoading);
  const { status: userStatus } = useGetUserDataByUsername();

  useEffect(() => {
    setIsAppCtxLoading(userStatus === 'loading');
  }, [userStatus]);

  if (isAppCtxLoading) {
    return <Loader isLarge />;
  }

  return (
    <div className="h-screen flex flex-col justify-start">
      <Header />
      <main style={{ fontFamily: setup?.fonts.body }} className="grow">
        {children}
      </main>
      <Footer />
      <SEOFavicoComponent />
    </div>
  );
};

export default Layout;
