import { IFeaturedData } from 'provider/FeaturedProvider/FeaturedProvider.interface';
import { request } from './app';

export interface IContentSection {
  answer: string;
  audio_ios_url: string;
  audio_url: string;
  headline: string;
  id: string;
  image_url: string;
  question: string;
  question_1: string;
  question_2: string;
  question_3: string;
  question_4: string;
  question_5: string;
  sub_description: string;
  text: string;
  web_text: string;
  title: string;
  video_url: string;
  sort_order: number;
}

interface MediaMetaData {
  [key: string]: {
    filename: string;
    id: string;
    type: string;
    url: string;
    url_with_extension: string;
  };
}

export interface IListItem {
  action_bar_url: string;
  action_bar_text: string;
  audio_ios_url: string;
  audio_url: string;
  description: string;
  has_details: boolean;
  id: string;
  image_url: string;
  sort_order: number;
  text: string;
  video_url: string;
}
export interface IContent {
  id: string;
  is_subscribed?: boolean;
  title: string;
  web_description: string;
  description: string;
  author: string;
  video_url: string;
  image_url: string;
  audio_url: string;
  audio_ios_url: string;
  action_bar_text: string;
  action_bar_url: string;
  release_date: string;
  price?: string;
  review_video_url?: string;
  review_image_url?: string;
  sections?: IContentSection[];
  document_url?: string;
  media_metadata?: MediaMetaData;
  items?: IListItem[];
  length: number;
  question_1?: string;
  question_2?: string;
  question_3?: string;
  question_4?: string;
  question_5?: string;
  cost?: string;
  can_subscribe?: boolean;
}

export interface IContentQuestion {
  question_1?: string;
}

export interface IJournalAnswer {
  answer_1?: string;
}

interface IProfile {
  web_bio: string;
  bio: string;
  profile_image_url: string;
  action_bar_url: string;
  action_bar_text: string;
  title: string;
  name: string;
  business_name: string;
  twitter_url: string;
  facebook_url: string;
  linkedin_url: string;
  youtube_url: string;
  instagram_url: string;
  email: string;
}

export interface IOffer {
  id: string;
  title: string;
  message: string;
  author: string;
  video_url: string;
  image_url: string;
  audio_url: string;
  audio_ios_url: string;
  action_bar_text: string;
  action_bar_url: string;
  release_date: string;
  price?: string;
  review_video_url?: string;
  review_image_url?: string;
  sections?: IContentSection[];
  document_url?: string;
  media_metadata?: MediaMetaData;
}
export const getContentByUuid =
  (uuid?: string, token?: string) => async (): Promise<IContent | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;

    const response = await request(
      `${String(process.env.REACT_APP_API)}/content/${uuid}`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getOffersByUuid =
  (uuid?: string, token?: string) => async (): Promise<IOffer | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;

    const response = await request(
      `${String(process.env.REACT_APP_API)}/offers/${uuid}`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getProfileByUuid =
  (uuid?: string, token?: string) => async (): Promise<IProfile | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;

    const response = await request(
      `${String(process.env.REACT_APP_API)}/profiles/${uuid}`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getFeaturedContentByUuid =
  (top: boolean, uuid?: string | null, token?: string) =>
  async (): Promise<IFeaturedData[] | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;

    const response = await request(
      `${String(process.env.REACT_APP_API)}/apps/${uuid}/featured_content?top=${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        top
      }`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getFeaturedContentByType =
  (type: string, uuid?: string | null, token?: string) =>
  async (): Promise<IFeaturedData[] | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;

    const response = await request(
      `${String(process.env.REACT_APP_API)}/apps/${uuid}/content/${type}`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getMemberContentByType =
  (type: string, uuid?: string | null, token?: string) =>
  async (): Promise<IFeaturedData[] | null> => {
    if (typeof uuid === 'undefined' || uuid === null || uuid === 'null')
      return null;

    const response = await request(
      `${String(
        process.env.REACT_APP_API,
      )}/apps/${uuid}/content/${type}?member_content=true`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const getMemberContentByTypeAndTag =
  (type: string, tag_id: string, uuid?: string | null, token?: string) =>
  async (): Promise<IFeaturedData[] | null> => {
    if (typeof uuid === 'undefined' || uuid === null || uuid === 'null')
      return null;

    const response = await request(
      `${String(
        process.env.REACT_APP_API,
      )}/apps/${uuid}/content/${type}?member_content=true&tag_id=${tag_id}`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const subscribeToContent = async (
  uuid?: string | null,
  token?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/content/${String(uuid)}/subscribe`,
    'POST',
    token,
  );

  if (!response?.ok) {
    return await response.json();
  }

  return response;
};

export const unsubscribeToContent = async (
  uuid?: string | null,
  token?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/content/${String(uuid)}/unsubscribe`,
    'POST',
    token,
  );

  if (!response?.ok) {
    throw new Error('Unable to unsubscribe to content');
  }

  return response;
};
